import { useEffect, useMemo } from 'react'

import { useGetThemesQuery } from 'modules/api'
import { useAppDispatch } from 'modules/redux'
import {
  setGlobalThemes,
  setWorkspaceThemes,
} from 'modules/theming/themePicker/reducer'
import { getCurrentWorkspaceId } from 'modules/user'

export const useFetchAndStoreThemePickerData = () => {
  const workspaceId = getCurrentWorkspaceId()!
  const dispatch = useAppDispatch()

  const { data: workspaceData, loading: loadingWorkspaceThemes } =
    useGetThemesQuery({
      variables: {
        workspaceId,
        archived: false,
      },
      skip: !workspaceId,
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
    })
  const { data: globalData, loading: loadingGlobalThemes } = useGetThemesQuery({
    variables: {
      // @ts-ignore
      workspaceId: null,
      archived: false,
    },
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
  })

  const globalThemes = useMemo(
    () => globalData?.themes || [],
    [globalData?.themes]
  )
  const workspaceThemes = useMemo(
    () => workspaceData?.themes || [],
    [workspaceData?.themes]
  )

  useEffect(() => {
    if (globalThemes.length > 0) {
      dispatch(setGlobalThemes({ globalThemes }))
    }
  }, [dispatch, globalThemes])

  useEffect(() => {
    if (workspaceThemes.length > 0) {
      dispatch(setWorkspaceThemes({ workspaceThemes }))
    }
  }, [dispatch, workspaceThemes])

  return {
    isLoadingThemes: loadingWorkspaceThemes || loadingGlobalThemes,
    globalThemes,
  }
}
