import { QueueableTask } from 'modules/ai/chat/types'
import { PageSetupTabs } from 'sections/docs/editor/components/Toolbar/PageSetup/types'
import { EventEmitter } from 'utils/EventEmitter'

export enum EventBusEvent {
  CREATE_COMMENT_FROM_SELECTION = 'createCommentFromSelection',
  OPEN_POPUP_COMMENT = 'openPopupComment',
  POPUP_COMMENT_TOGGLED = 'popupCommentToggled',
  OPEN_THEME_DRAWER = 'openThemeDrawer',
  OPEN_THEME_EDITOR = 'openThemeEditor',
  OPEN_PAGE_SETUP = 'openPageSetup',
}

type TiptapEventPayloads = {
  [EventBusEvent.CREATE_COMMENT_FROM_SELECTION]: {
    selectionPos: number
    parentPos: number
    text: string
  }
  [EventBusEvent.OPEN_POPUP_COMMENT]: {
    commentId: string
    highlightComment?: boolean
  }
  [EventBusEvent.POPUP_COMMENT_TOGGLED]: {
    open: boolean
  }
  queueDesignPartnerTask: QueueableTask
  [EventBusEvent.OPEN_THEME_DRAWER]: {}
  [EventBusEvent.OPEN_THEME_EDITOR]: {}
  [EventBusEvent.OPEN_PAGE_SETUP]: PageSetupTabs
}

export class EventBus extends EventEmitter<TiptapEventPayloads> {}

export const TiptapEventBus = new EventBus()
