import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Link, useToast } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Editor } from '@tiptap/core'
import { useCallback, useEffect, useMemo } from 'react'

import {
  ExistingWorkspace,
  Font,
  FontSortField,
  GetThemesDocument,
  SortDirection,
  ThemeFont,
  useArchiveThemeMutation,
  useGetGlobalFontsQuery,
  useGetThemeQuery,
  useGetWorkspaceFontsQuery,
  useUnarchiveThemeMutation,
  useUpdateDocThemeMutation,
} from 'modules/api'
import { replaceState } from 'modules/history'
import { useAppSelector } from 'modules/redux'
import { isThemeDark, Theme, THEMES_DASHBOARD_LINK } from 'modules/theming'
import { useFetchAndStoreThemePickerData } from 'modules/theming/themePicker/hooks/useFetchAndStoreThemePickerData'
import { useThemeReducer } from 'modules/theming/themeReducer/reducer'
import { EventBusEvent, TiptapEventBus } from 'modules/tiptap_editor/eventBus'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import { getExistingQueryParams } from 'utils/url'

import { ARCHIVED_THEME_NAME_REPLACE_REGEX, EMPTY_THEME } from './constants'
import { FontMap } from './types'
import { getDefaultNewTheme, makeCleanCustomTheme } from './utils/utils'
export const useGetFonts = (workspaceId?: ExistingWorkspace['id']) => {
  const { data: globalFontData } = useGetGlobalFontsQuery()
  const { data: workspaceFontData } = useGetWorkspaceFontsQuery({
    variables: {
      workspaceId,
      sortBy: { field: FontSortField.Name, direction: SortDirection.Asc },
    },
    skip: !workspaceId,
  })

  const [globalFonts, workspaceFonts, fontsMap, allThemeFonts] = useMemo(() => {
    const global = globalFontData?.fonts || []
    const workspace = workspaceFontData?.fonts || []
    const allFonts = [...global, ...workspace]
    const map: FontMap = allFonts.reduce<{
      [key: string]: Font
    }>((acc, thisFont) => {
      acc[thisFont.id] = thisFont
      return acc
    }, {})

    const fontsForAllThemes: ThemeFont[] = allFonts.map((font) => ({
      id: font.id,
      name: font.name,
      url: font.cssUrl,
    }))

    return [global, workspace, map, fontsForAllThemes]
  }, [globalFontData?.fonts, workspaceFontData?.fonts])

  return {
    globalFonts,
    workspaceFonts,
    fontsMap,
    allThemeFonts,
  }
}

export const useUpdateDocThemeWithAccentImages = ({
  docId,
  editor,
}: {
  docId?: string
  editor?: Editor | null
}) => {
  const [updateDocTheme] = useUpdateDocThemeMutation()

  const updateDocThemeWithAccentImages = useCallback(
    async (themeId: string) => {
      if (!docId) {
        return
      }
      return updateDocTheme({
        variables: { id: docId, themeId },
      }).then(({ data }) => {
        if (editor) {
          editor.commands.updateThemeAccentImages(
            data?.updateDoc?.theme?.config.accentBackgrounds
          )
        }
      })
    },
    [docId, editor, updateDocTheme]
  )

  return { updateDocThemeWithAccentImages }
}

export const useIsThemeDark = () => {
  const theme = useAppSelector(selectTheme)
  return isThemeDark(theme)
}

/**
 * Hook that will open the theme editor with a theme
 * - if url query params have `openThemeEditor=true`
 * - for Tiptap OPEN_THEME_EDITOR events emitted
 */
export const useOpenThemeEditor = (
  editor: Editor | null,
  onThemeEditorOpen: () => void,
  dispatch: ReturnType<typeof useThemeReducer>[1]
) => {
  const theme: Theme = useAppSelector(selectTheme)
  const { globalThemes } = useFetchAndStoreThemePickerData()

  const openThemeEditor = useCallback(
    (themeToEdit?: Theme, isNewTheme = false) => {
      if (!themeToEdit) {
        dispatch({ type: 'NEW_BLANK_THEME' })
      } else if (isNewTheme) {
        dispatch({
          type: 'SET_NEW_THEME_FOR_SELECTING',
          data: {
            theme: themeToEdit,
          },
        })
      } else {
        dispatch({
          type: 'SET_THEME_EDITING',
          data: {
            theme: themeToEdit,
          },
        })
      }
      onThemeEditorOpen()
    },
    [dispatch, onThemeEditorOpen]
  )

  const openThemeEditorWithDefaultTheme = useCallback(() => {
    const defaultTheme = getDefaultNewTheme(globalThemes)
    openThemeEditor(defaultTheme, true)
  }, [globalThemes, openThemeEditor])

  useEffect(() => {
    const openThemeEditorWithCurrentTheme = () => {
      if (theme.workspaceId === null) {
        const newThemeToEdit = makeCleanCustomTheme(theme, true)
        openThemeEditor(newThemeToEdit)
      } else {
        openThemeEditor(theme)
      }
    }
    const unsubTiptapEvent = TiptapEventBus.on(
      EventBusEvent.OPEN_THEME_EDITOR,
      openThemeEditorWithCurrentTheme
    )
    return () => {
      unsubTiptapEvent()
    }
  }, [theme, openThemeEditor])

  useEffect(() => {
    const queryParams = getExistingQueryParams()
    if (
      editor &&
      // If there's a parameter called `openThemeEditor` in the URL, open the theme drawer
      queryParams['openThemeEditor'] === 'true'
    ) {
      // Will open with a new "blank" theme
      openThemeEditor()
      // Clean up
      replaceState({
        query: {
          ...getExistingQueryParams(),
          openThemeEditor: undefined,
        },
        emitChange: false,
      })
    }
  }, [editor, openThemeEditor])

  return {
    openThemeEditor,
    openThemeEditorWithDefaultTheme,
  }
}

export const useGetThemeById = (id: string): Theme => {
  const { data } = useGetThemeQuery({
    variables: {
      id,
    },
    fetchPolicy: 'cache-first',
  })
  return data?.theme || EMPTY_THEME
}

export const useUnarchiveTheme = ({ theme }: { theme: Theme }) => {
  const toast = useToast()
  const [_unarchiveTheme] = useUnarchiveThemeMutation()
  const displayName = theme.name.replace(ARCHIVED_THEME_NAME_REPLACE_REGEX, '')
  const unarchiveTheme = useCallback(() => {
    if (!theme || !theme.id) return
    toast.close('archive-theme-toast')
    _unarchiveTheme({
      variables: { id: theme.id },
      update: (cache, { data }) => {
        if (!data?.unarchiveTheme) return

        cache.writeQuery({
          query: GetThemesDocument,
          variables: {
            workspaceId: theme.workspaceId,
            archived: false,
          },
          data: {
            themes: [data.unarchiveTheme],
          },
        })
      },
      optimisticResponse: {
        unarchiveTheme: {
          __typename: 'Theme',
          ...theme,
          archived: false,
          updatedTime: new Date().toISOString(),
        },
      },
      refetchQueries: ['GetThemes'],
    })
      .then(() => {
        toast({
          id: 'unarchive-theme-toast',
          title: t`Theme ${displayName} has been restored`,
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
      })
      .catch((err) => {
        console.error(`Couldn't restore theme ${displayName} error: ${err}`)
      })
  }, [_unarchiveTheme, theme, toast, displayName])

  return unarchiveTheme
}

const ToastDescription = ({
  showLinkToDashboard,
  unarchiveTheme,
}: {
  showLinkToDashboard?: boolean
  unarchiveTheme: () => void
}) => {
  return showLinkToDashboard ? (
    <Box>
      <Trans>
        Go to{' '}
        <Link
          textDecoration="underline"
          isExternal
          href={THEMES_DASHBOARD_LINK}
        >
          theme dashboard <ExternalLinkIcon mx="2px" />
        </Link>{' '}
        or{' '}
        <Link
          textDecoration="underline"
          as={Link}
          variant="link"
          onClick={unarchiveTheme}
        >
          undo
        </Link>
      </Trans>
    </Box>
  ) : (
    <Box>
      <Trans>
        Made a mistake?{' '}
        <Link
          textDecoration="underline"
          as={Link}
          variant="link"
          onClick={unarchiveTheme}
        >
          Restore theme
        </Link>
        .
      </Trans>
    </Box>
  )
}
export const useArchiveTheme = ({
  theme,
  showLinkToDashboard = false,
}: {
  theme: Theme
  showLinkToDashboard?: boolean
}) => {
  const toast = useToast()
  const [_archiveTheme] = useArchiveThemeMutation()
  const unarchiveTheme = useUnarchiveTheme({ theme })
  const displayName = theme.name.replace(ARCHIVED_THEME_NAME_REPLACE_REGEX, '')
  const archiveTheme = useCallback(() => {
    if (!theme || !theme.id) return

    _archiveTheme({
      variables: { id: theme.id },
      update: (cache, { data }) => {
        if (!data?.archiveTheme) return

        cache.writeQuery({
          query: GetThemesDocument,
          variables: {
            workspaceId: theme.workspaceId,
            archived: false,
          },
          data: {
            themes: [data.archiveTheme],
          },
        })
      },
      optimisticResponse: {
        archiveTheme: {
          __typename: 'Theme',
          ...theme,
          archived: true,
          updatedTime: new Date().toISOString(),
        },
      },
      refetchQueries: ['GetThemes'],
    })
      .then(() => {
        toast({
          id: 'archive-theme-toast',
          title: t`Theme ${theme.name} has been archived`,
          description: (
            <ToastDescription
              showLinkToDashboard={showLinkToDashboard}
              unarchiveTheme={unarchiveTheme}
            />
          ),
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
      })
      .catch((err) => {
        console.error(`Couldn't archive theme ${displayName} error: ${err}`)
      })
  }, [
    theme,
    _archiveTheme,
    toast,
    showLinkToDashboard,
    unarchiveTheme,
    displayName,
  ])

  return archiveTheme
}
